<template>
    <div
class="search__container" :class="{
        'search__container--expandable': props.expandable,
        'is-expanded': isExpanded
    }">
        <div class="search__form">
            <input
                ref="input"
                type="text"
                :value="modelValue"
                :placeholder="props.placeholder ?? $t('General.search')"
                @input="onInput"
                @keydown="onKeyDown"
                @blur="onBlur"
            >
            <AtomButton
                v-if="!expandable"
                class="search__icon"
                type="tertiary"
                size="m"
                icon="search"
                @click="triggerSearch"
            />
            <AtomButton
                v-else
                class="search__icon search__icon--clear"
                :class="{ 'is-invisible': modelValue.length === 0 }"
                type="tertiary"
                size="m"
                icon="x"
                icon-width="24"
                icon-bolding="2"
                @click="clearSearch()"
            />
        </div>
        <AtomButton
            v-if="expandable"
            :type="isExpanded ? 'primary' : buttonType"
            class="search__button"
            size="l"
            icon="search"
            @click="triggerSearch()"
        />
    </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
    modelValue: string,
    expandable?: boolean,
    buttonType?: 'primary' | 'secondary' | 'tertiary',
    autoClose?: boolean,
    placeholder?: string,
    expanded?: boolean,
}>();

const emit = defineEmits(['update:modelValue', 'search', 'expand', 'update:queryParams']);

const input = ref<HTMLInputElement | null>(null);
const isExpanded = ref(props.expanded || props.modelValue.length > 0);

const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('update:modelValue', target.value);
};

const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
        emit('search', props.modelValue);
    }
};

const onBlur = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (props.autoClose && props.expandable && !target.value.length) {
        isExpanded.value = false;
    }
};

const triggerSearch = () => {
    if (props.expandable && !isExpanded.value) {
        isExpanded.value = true;
    }

    if (!input.value!.value.length) {
        nextTick(() => {
            input.value!.focus();
        });
    }

    emit('search', props.modelValue);
};

const clearSearch = () => {
    input.value!.focus();
    emit('update:modelValue', '');
    emit('search', '');
    emit('update:queryParams', []);
};

const clear = () => {
    clearSearch();
    isExpanded.value = false;
};

watch(isExpanded, (newValue: boolean) => {
    if (newValue) {
        emit('expand', newValue);
    }
});

defineExpose({
    clear,
});
</script>

<style lang="scss" scoped>
.search {
    &__form {
        padding: sp(xxs) sp(xs);
        display: flex;
        align-items: center;

        @include helper-border($setting-color-state-disabled-text, rem(1), solid);
        @include helper-border-radius(rem(5));
    }

    &__button {
        width: rem(48);
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        input {
            width: 100%;

            @include helper-border-radius($setting-border-radius-input);
            @include helper-color(inherit);
            @include helper-color-bg(transparent-background);
            @include helper-font-size(6);
            @include helper-border($setting-color-transparent-background, rem(1), solid);
        }

        input::placeholder {
            @include helper-color(state-disabled-text);
        }

        &--expandable {
            flex-direction: row;

            .search__form {
                width: 17.5rem;
                height: 3rem;
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:not(.is-expanded) {
                .search__form {
                    display: none;
                }
            }

            &.is-expanded {
                .search__button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}
</style>

export default function useDeepCompare(left: {[key: string]: any}, right: {[key: string]: any}): boolean {
    const aKeys = Object.keys(left);
    if (aKeys.length !== Object.keys(right).length) {
        return false;
    }
    for (const key of aKeys) {
        if (typeof left[key] === 'object') {
            if (typeof right[key] === 'object') {
                if (!useDeepCompare(left[key], right[key])) {
                    return false;
                }
            } else {
                return false;
            }
        } else if (left[key] !== right[key]) {
            return false;
        }
    }

    return true;
}
